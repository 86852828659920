<template>
  <template v-for="(part, i) in parts">
    <b v-if="i === 1" :key="i">{{ part }}</b>
    <template v-else>{{ part }}</template>
  </template>
</template>

<script setup>
const props = defineProps({
  content: String,
  query: String
})

const parts = computed(() => {
  const query = props.query?.trim().toLowerCase()
  if (!query) return [props.content]

  const start = props.content.toLowerCase().indexOf(query)
  const end = start + query.length
  return [
    props.content.slice(0, start),
    props.content.slice(start, end),
    props.content.slice(end)
  ]
})
</script>

<style scoped lang="scss">

</style>
